<template>
	<el-menu :default-active="index" :router="true" active-text-color="#ffd04b" background-color="#34495e" :class="isCollapse ? 'menu_un_collapse' : 'menu_collapse'" text-color="#fff">
		<div style="background: #25262d; text-align: center; height: 30px; color: white; line-height: 30px; cursor: pointer; font-size: 18px" @click="change_menu_collapse">
			<i id="menu_collapse" :class="isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
		</div>
		<el-menu-item :route="bind_menu_path('Route')" index="1">
			<i class="layui-icon layui-icon-website"></i>
			<span v-show="isCollapse" slot="title">路由管理</span>
		</el-menu-item>
		<el-menu-item :route="bind_menu_path('Project')" index="2">
			<i class="layui-icon layui-icon-list"></i>
			<span v-show="isCollapse" slot="title">项目管理</span>
		</el-menu-item>
		<el-menu-item :route="bind_menu_path('Document')" index="3">
			<i class="layui-icon layui-icon-read"></i>
			<span v-show="isCollapse" slot="title">文档管理</span>
		</el-menu-item>
		<el-menu-item :route="bind_menu_path('SystemConfig')" index="4">
			<i class="layui-icon layui-icon-set"></i>
			<span v-show="isCollapse" slot="title">系统管理</span>
		</el-menu-item>
	</el-menu>
</template>

<script>
export default {
	name: 'Menu.vue',
	props: {
		index: String
	},
	data() {
		return {
			isCollapse: true
		}
	},
	created() {
		let collapse = localStorage.getItem('menu_is_collapse')
		this.isCollapse = collapse === '0' ? false : true
	},
	methods: {
		change_menu_collapse() {
			this.isCollapse = !this.isCollapse
			localStorage.setItem('menu_is_collapse', this.isCollapse ? '1' : '0')
		},
		bind_menu_path(path) {
			return { path: path }
		}
	}
}
</script>

<style scoped>
@import '../../assets/css/iconfont.css';
.menu_collapse {
	width: 60px;
	left: 0;
	bottom: 0;
	background-color: #34495e;
}
.menu_un_collapse {
	width: 200px;
	left: 0;
	bottom: 0;
	background-color: #34495e;
}

.layui-icon {
	font-size: 18px;
	color: white;
	margin-right: 5px;
}
</style>